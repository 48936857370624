const faqs = [
    {
        id: 1,
        question: "IPv4 address",
        answer:
            "curl v4.meipbe.com",
    },
    {
        id: 2,
        question: "IPv6 address",
        answer:
            "curl v6.meipbe.com",
    },
    {
        id: 3,
        question: "JSON response",
        answer:
            "curl meipbe.com/api/v1/ip",
    },
]

export default function FAQ() {
    return (
        <div>
            <div className="mx-auto px-4 mt-2 md:mt-0 md:px-0">
                <h2 className="text-xl font-semibold tracking-tight text-stone-200 md:-mt-2">Other ways to check your IP</h2>
                <dl className="divide-y divide-stone-200/10 mt-2">
                    {faqs.map((faq) => (
                        <div key={faq.id} className="py-3">
                            <dt className="text-sm font-semibold leading-7 text-stone-300">{faq.question}</dt>
                            <dd>
                                <p className="text-sm leading-7 text-stone-400">{faq.answer}</p>
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}