import React from 'react';
import './tailwind.css';
import Table from "./table";

function App() {
  return (
    <div className="h-full bg-stone-50 mx-auto">
      <Table />
    </div>
  );
}

export default App;
