'use client';
import {useEffect, useState} from "react";
import Notification from "./notification";
import {ClipboardIcon, GlobeAltIcon} from "@heroicons/react/24/outline";
import FAQ from "./faq";
import {CheckCircleIcon} from "@heroicons/react/16/solid";
import DNSLeakTest from "./dnsTest";

export default function Table() {
  const [IPv4, setIPv4] = useState('Loading...');
  const [IPv4Country, setIPv4Country] = useState('Loading...');
  const [IPv4City, setIPv4City] = useState('Loading...');
  const [IPv4ISO, setIPv4ISO] = useState('Loading...');
  const [IPv6, setIPv6] = useState('Loading...');
  const [IPv6Country, setIPv6Country] = useState('Loading...');
  const [IPv6City, setIPv6City] = useState('Loading...');
  const [IPv6ISO, setIPv6ISO] = useState('Loading...');
  const [ipCopied, setIPCopied] = useState('');


  useEffect(() => {
    fetchData();

    const handleKeyDown = (event) => {
        if (event.key === 'r' || event.key === 'R') {
            fetchData();
        }
    }

    // Add event listener
    window.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const fetchData = async () => {
    setIPv4('Loading...');
    setIPv4Country('');
    setIPv4City('');
    setIPv4ISO('');
    setIPv6('Loading...');
    setIPv6Country('');
    setIPv6City('');
    setIPv6ISO('');

    fetch('https://v4.meipbe.com/api/v1/ip')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network v4 response was not ok');
          }

          return response.json();
        })
        .then(response => {
          setIPv4(response.data.ip || 'Error fetching IPv4 address')
          setIPv4Country(response.data.country || 'MaxMind data missing')
          setIPv4City(response.data.city || '')
          setIPv4ISO(response.data.iso_code || '')
        })
        .catch(() => {
            setIPv4('Not available')
            setIPv4Country('')
            setIPv4City('')
            setIPv4ISO('')
        });

    fetch('https://v6.meipbe.com/api/v1/ip')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network v6 response was not ok');
          }

          return response.json();
        })
        .then(response => {
          setIPv6(response.data.ip || 'Error fetching IPv6 address')
          setIPv6Country(response.data.country || 'MaxMind data missing')
          setIPv6City(response.data.city || '')
          setIPv6ISO(response.data.iso_code || '')
        })
        .catch(() => {
            setIPv6('Not available')
            setIPv6Country('')
            setIPv6City('')
            setIPv6ISO('')
        });
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIPCopied(text);
      setTimeout(() => setIPCopied(''), 3000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

    return (
    <div className="container mx-auto max-w-xl">
      <Notification ip={ipCopied} />

      <div className="md:py-8 md:flex items-center justify-center">
          <div
              className="md:bg-white md:rounded-xl md:min-w-96 shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-2 pt-4 md:p-8 md:pb-0">
                  <div className="px-4 sm:px-0">
                      <h3 className="text-base font-semibold leading-7 text-stone-600 md:-mt-2">Me IP Be</h3>
                      <p className="mt-1 max-w-2xl text-sm leading-6 text-stone-500">Discover your IP address, inspired
                          by
                          Star Wars' Jar Jar Binks. It's perfect for fans of the franchise and anyone who wants to learn
                          more about their IPv4 or IPv6 address.</p>
                  </div>
                  <div className="mt-6 border-t border-stone-200">
                      <dl className="divide-y divide-stone-200">
                          <div onClick={() => copyToClipboard(IPv4)}
                               className="px-4 py-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-stone-600">
                                  <ClipboardIcon className="h-4 w-4 text-stone-600 inline mr-2 -mt-1" aria-hidden="true"/>
                                  IPv4
                              </dt>
                              <dd
                                  className="mt-2 text-sm leading-6 text-stone-600">{IPv4}</dd>

                              {IPv4Country.length > 0 ?
                                  <>
                                      <p className="text-sm leading-6 text-stone-400 sm:col-span-2 sm:mt-0">
                                          <img src={`/flags/${IPv4ISO}.svg`} alt=""
                                               className="h-4 w-4 inline mr-2 -mt-1" aria-hidden="true"/>

                                          {IPv4Country} ({IPv4ISO}), {IPv4City}
                                      </p>
                                  </>
                                  :
                                  <>
                                      <p className="text-sm leading-6 text-stone-400 sm:col-span-2 sm:mt-0">
                                          -
                                      </p>
                                  </>
                              }
                          </div>
                          <div onClick={() => copyToClipboard(IPv6)}
                               className="px-4 py-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-stone-600">
                                  <ClipboardIcon className="h-4 w-4 text-stone-600 inline mr-2 -mt-1" aria-hidden="true"/>
                                  IPv6
                              </dt>
                              <dd
                                  className="mt-2 text-sm leading-6 text-stone-600 ">{IPv6}</dd>

                              {IPv6Country.length > 0 ?
                                  <>
                                      <p className="text-sm leading-6 text-stone-400 sm:col-span-2 sm:mt-0">
                                          <img src={`/flags/${IPv6ISO}.svg`} alt=""
                                               className="h-4 w-4 inline mr-2 -mt-1" aria-hidden="true"/>

                                          {IPv6Country} ({IPv6ISO}), {IPv6City}
                                      </p>
                                  </>
                                  :
                                  <>
                                  <p className="text-sm leading-6 text-stone-400 sm:col-span-2 sm:mt-0">
                                          -
                                      </p>
                                  </>
                              }

                          </div>
                      </dl>
                  </div>
              </div>

              <div className="px-4">
                  <div
                      className="hidden bg-stone-50 md:flex flex-wrap items-center px-4 py-2.5 text-xs text-stone-600 rounded-xl">
                      Press{' '}
                      <kbd
                          className="mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2 border-stone-400 text-stone-800">
                          R
                      </kbd>{' '}
                      <span className="inline">to reload IP check</span>
                  </div>

                  <div
                      onClick={fetchData}
                      className="md:hidden bg-stone-100 active:bg-stone-200 flex flex-wrap items-center px-4 py-2.5 text-xs text-stone-600 rounded-xl">
                      > Press here to reload IP check
                  </div>
              </div>

              <DNSLeakTest />

              <div className="bg-stone-900 md:rounded-b-lg p-2 pt-4 md:p-8">
                  <FAQ/>

                  <div className="border-t border-stone-600 mt-3">
                      <p className="text-sm text-stone-400 mt-6 px-4 md:p-0">Privacy-focused
                          IP lookup tool! For privacy-conscious individuals who value anonymity and
                          demand a no-external-trace, zero-log environment.</p>
                      <p className="text-sm text-stone-400 md:mt-3 p-4 md:p-0">Built by <a className="text-stone-200"
                                                                                           target="_blank"
                                                                                           href="https://williamo.dev">williamo.dev</a>,
                          this site is part of a wider effort to improve digital privacy. <span
                              className="border-b border-stone-600">One project at a time.</span></p>

                      <p className="text-xs text-stone-500 md:mt-3 px-4 pb-3 md:p-0">MaxMind
                          GeoLite2-City 2024-03-01</p>
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}
