import React, { useState, useEffect } from 'react';

function DNSLeakTest() {
    const [uniqueId, setUniqueId] = useState('');
    const [servers, setServers] = useState([]);
    const [error, setError] = useState('');
    const [requestCount, setRequestCount] = useState(0); // State to track request count

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // Function to fetch unique ID
    const fetchUniqueId = async () => {
        try {
            const response = await fetch('https://meipbe.com/api/v1/dns');
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setUniqueId(data.data.servers[0].id);
        } catch (err) {
            setError('Failed to fetch unique ID');
        }
    };

    // Function to generate a random dummy string
    const generateDummyString = () => {
        return Math.random().toString(36).substring(2, 15);
    };

    // Function to make dummy API requests
    const makeDummyRequest = () => {
        if (!uniqueId) return; // Ensure we have a unique ID first

        const dummyString = generateDummyString();
        const url = `https://${uniqueId}.${dummyString}.mednsbe.com`;

        // Increment request count
        setRequestCount(count => count + 1);

        // Make a dummy fetch request to the constructed URL
        fetch(url)
            .then(response => console.log(`Response from ${url}:`, response))
            .catch(error => console.log(`Error fetching ${url}:`, error));
    };

    // Periodically make a dummy request while the page is open
    useEffect(() => {
        makeDummyRequest(); // Make initial dummy request
        makeDummyRequest(); // Make initial dummy request
        makeDummyRequest(); // Make initial dummy request
        makeDummyRequest(); // Make initial dummy request
        makeDummyRequest(); // Make initial dummy request

        const interval = setInterval(makeDummyRequest, 1000); // Make a dummy request every  second
        return () => clearInterval(interval);
    }, [uniqueId]); // Rerun when uniqueId changes

    // Function to fetch DNS server list
    const fetchServers = async () => {
        if (!uniqueId) return; // Do nothing if uniqueId hasn't been fetched
        try {
            const response = await fetch(`https://meipbe.com/api/v1/dns/result?id=${uniqueId}`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            // Append new servers to the existing list
            setServers(data.data);
            setError(''); // Clear any previous errors
        } catch (err) {
            setError('Failed to fetch server list');
        }
    };

    // Fetch unique ID on component mount
    useEffect(() => {
        fetchUniqueId();
    }, []);

    // Periodically fetch the server list while the page is open
    useEffect(() => {
        if (uniqueId) {
            setTimeout(fetchServers, 1000);

            const interval = setInterval(fetchServers, 3000); // Fetch every 5 seconds
            return () => clearInterval(interval);
        }
    }, [uniqueId]);

    return (
        <div>
            <div className="p-6 md:pt-0">
                <div className="px-1 pt-0 pb-3 md:pt-6">
                    <p className="text-sm font-medium leading-6 text-stone-600">
                        DNS <span className="text-stone-400 text-xs font-normal">{requestCount} tests performed</span>
                    </p>

                    <p className="flex-auto py-0.5 text-xs leading-5 text-stone-500">The IPs listed below show which DNS servers are handling your lookups. If they're not from your chosen DNS provider or VPN, it may indicate a DNS leak, potentially exposing your online activity.</p>
                </div>

                <ul role="list" className="space-y-3">
                    {servers.length > 0 ?
                    <>
                        {servers.map((server, index) => (
                            <li key={index} className="relative flex gap-x-1">
                                <div
                                    className={classNames(
                                        index === servers.length - 1 ? 'h-6' : '-bottom-6',
                                        'absolute left-0 top-0 flex w-6 justify-center'
                                    )}
                                >
                                    <div className="w-px bg-stone-200"/>
                                </div>
                                <div
                                    className="relative flex h-6 w-6 flex-none items-center justify-center bg-stone-50 md:bg-white">
                                    <div
                                        className="h-1.5 w-1.5 rounded-full bg-stone-100 ring-1 ring-stone-300"/>
                                </div>
                                <p className="flex-auto py-0.5 text-xs leading-5 text-stone-500">
                                    <span className="text-stone-700">{server.ip}</span> - {server.isp}
                                </p>
                            </li>
                        ))}
                    </>
                    :
                    <>
                        {error ?
                            <>
                                <p className="pl-1 flex-auto py-0.5 text-xs leading-5 text-stone-500">Error: {error}</p>
                            </>
                            :
                            <>
                                <p className="pl-1 flex-auto py-0.5 text-xs leading-5 text-stone-500">Loading...</p>
                            </>
                        }
                    </>}
                </ul>
            </div>
        </div>
    );
}

export default DNSLeakTest;